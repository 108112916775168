<template>
  <div class="corneaReview">
    <div class="exan-box">
      <ul class="left">
<!--        <li class="el-icon-caret-right" >裂隙灯检查</li>-->
        <li class="el-icon-caret-right" :class="idx==1?'xz':''" @click="teb(1)">镜片评估</li>
        <li class="el-icon-caret-right" :class="idx==2?'xz':''" @click="teb(2)">屈光评估</li>
        <li class="el-icon-caret-right" :class="idx==3?'xz':''" @click="teb(3)">戴镜评估</li>
        <li class="el-icon-caret-right" :class="idx==4?'xz':''" @click="teb(4)">处理建议</li>
        <li><el-button @click="$router.push({path: '/home/examinationEyesHome?idx=1&str=uf_baseoptometry'})" style="font-size: 12px;" type="primary" >裂隙灯检查</el-button></li>
        <li><el-button style="font-size: 12px;" @click="$router.push({path: '/corneaContact/sxjsyzhb'})" type="primary" >塑形镜使用综合评估表</el-button></li>
      </ul>
      <div class="rightp" ref="boxm">
        <lensAssessment class="border-b"></lensAssessment>
        <RefractiveAssessment class="border-b"></RefractiveAssessment>
        <WearGlasses class="border-b"></WearGlasses>
        <ClAdvice class="border-b"></ClAdvice>
      </div>
    </div>
    <el-button class="add" type="primary" @click="saves">{{bt}}保存</el-button>
  </div>
</template>

<script>
import specialSurveys from "@/publicFn/specialSurveys";

export default {
  name: "corneaReview",
  data() {
    return {
      idx: 0,
      allys: [],
      saveMap: new Map(),
      maps: new Map(),
      bt: '镜片评估',
      TimeMap: new Map()
    }
  },
  mounted() {
    this.idx = 1
    this.$refs.boxm.addEventListener('scroll', this._jl(this.boxbh, 300))
    this.allys = document.getElementsByClassName('border-b')
    this.maps.set(1, '镜片评估')
    this.maps.set(2, '屈光评估')
    this.maps.set(3, '戴镜评估')
    this.maps.set(4, '处理建议')

    this.TimeMap.set(1, "contact_glassEvaluation")
    this.TimeMap.set(2, "contact_optometryEvaluation")
    this.TimeMap.set(3, "contact_trailEvaluation")
    this.TimeMap.set(4, "contact_dealingSuggestion")
  },
  created() {
    this.$store.commit("corneaContact/upisfcshowdata", 0)
    if (!this.$store.state.users.CsUser) {
      return this.$alert('用户没有登录！请登录', '提示')
          .then(res => {
            this.$router.push({path: '/home/acceptsHome'})
          })
    }

    if (!this.$store.state.physicianVisits.xzConsulting) {
      return this.$alert('请选择或者新建咨询', '提示')
          .then(res => {
            this.$router.push({path: '/home/acceptsHome'})
          })
    }

    this.$store.commit('Time/upTimeObj', {
      keys: 'contact_glassEvaluation',
      val: (new Date()).valueOf()
    })

    this._api.corneaContact.getCorneaReview()
    .then(res => {
      if(res.GetListResult && res.GetListResult.length > 0) {
        this.$store.commit('corneaContact/upCorneaReview', this._clJson(res.GetListResult[0].S_OP_Json))
        this.$store.commit("corneaContact/upisfcshowdata", 1)
      }
    })
  },
  watch: {
    idx: function (n, o) {
        this.setTime(this.TimeMap.get(n))
    }
  },
  methods: {
    teb(idx) {
      this.idx = idx
      if (idx <= 3) {
        this.$refs.boxm.scrollTop = this.getDivH(idx-1, 0)
      }else if (idx > 3) {
        this.$refs.boxm.scrollTop = this.getDivH(idx-1, 0) + idx * 50
      }
    },
    boxbh() {
      let top = this.$refs.boxm.scrollTop
      this.clScrollTop(top)
    },
    getDivH(idx, type=1)  {
      if (idx < 1) return 0
      let h = 0
      if (type) {
        for (let i = 0; i <= idx; i++) {
          h = h + Number(this.allys[i].offsetHeight)
        }
      }else {
        for (let i = 0; i < idx; i++) {
          h = h + Number(this.allys[i].offsetHeight)
        }
      }
      return h
    },

    clScrollTop(h) {
      if (h < this.allys[0].offsetHeight*0.7) {
        this.idx = 1
        this.bt = '镜片评估'
      }
      else if (h > this.allys[0].offsetHeight*0.7 && h < this.getDivH(1)) {
        this.idx = 2
        this.bt = '屈光评估'
      }
      else if (h > this.getDivH(1, 0) && h < this.getDivH(2)) {
        this.idx = 3
        this.bt = '戴镜评估'
      }
      else if (h > this.getDivH(2, 0) && h < this.getDivH(3)) {
        this.idx = 4
        this.bt = '处理建议'
      }
    },

    saves() {
      if (this.$store.state.Time.timeObj[this.TimeMap.get(this.idx)].ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj[this.TimeMap.get(this.idx)].ENTER)/1000 + Number(this.$store.state.Time.timeObj[this.TimeMap.get(this.idx)].EXIT)
        this._api.publicApi.addTime(this.TimeMap.get(this.idx), times)
        this.jsTime(this.TimeMap.get(this.idx), 1)
      }
      this._api.corneaContact.saveCorneaReview()
    },


    setTime(str) {
      // console.log(      this.TimeMap.get(str))
      // console.log(this.TimeMap.keys())
      let arr =  Array.from(this.TimeMap.keys())
      for (let i = 0; i <arr.length; i++) {
        if (str == arr[i]) {
          this.cshTime(this.TimeMap.get(str))
        }else {
          this.jsTime(this.TimeMap.get(arr[i]))
        }
      }
    },
    cshTime(keys) {
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },
  components: {
    lensAssessment: () => import('@/components/corneaContact/corneaReview/lensAssessment'),
    RefractiveAssessment: () => import('@/components/corneaContact/corneaReview/refractiveAssessment'),
    WearGlasses: () => import('@/components/corneaContact/corneaReview/wearGlasses'),
    ClAdvice: () => import('@/components/corneaContact/corneaReview/clAdvice'),
  },
  beforeDestroy() {
    if (this.$store.state.Time.timeObj[this.TimeMap.get(this.idx)].ENTER != 0) {
      this.jsTime(this.TimeMap.get(this.idx))
    }
  }
}
</script>

<style scoped lang="scss">
  .border-b {
    border-bottom: 1px dotted #5a5959;
    padding-bottom: 0.3rem;
    margin-bottom: 1rem !important;
    width: 84vw;
    padding-left: 3vw;
  }
  .exan-box {
    width: 100vw;
    display: flex;
    justify-content: start;
    height: 87vh;
    overflow: hidden;
  }
  .rightp {
    width: calc(100vw - 200px);
    height: 87vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .left {
    width: 200px;
    font-size: 14px;
    text-align: left;
    border-right: 1px solid #bcbaba;
    overflow: hidden;
    overflow-y: auto;
    li {
      padding: 0.2rem 0.2rem;
      display: block;
      cursor: pointer;
      &:hover{
        background: #e7e7e7;
      }
    }
    .titles {background: #40a7f6; color: #ffffff;}
    .xz {background: #e7e7e7}
  }
  .left::-webkit-scrollbar { width: 0 !important }
  .rightp::-webkit-scrollbar { width: 0 !important }
  .add {
    position: fixed;
    right: 0.1rem;
    bottom: 3rem;
  }
</style>
